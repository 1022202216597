<template>
  <div class="container container_collect">
    <div class="navBar_m">
      <div class="leftIcon iconfont icon-fanhui" @click="back()"></div>
      <div class="navBarText_m">我的收藏</div>
    </div>
    <div class="pageTitle" v-if="!mobile">我的收藏</div>
    <div class="selectWrap">
      <div class="selectItem" v-for="(item, index) in selectList" :key="index">
        <div class="label">{{ item.label }}</div>
        <div class="tags">
          <div
            class="tag"
            :class="{ active: tagItem.id == selectList[index].currentIndex }"
            v-for="tagItem in item.children"
            :key="tagItem.id"
            @click="tagClick(index, tagItem.id)"
          >
            {{ tagItem.tagName }}
          </div>
        </div>
      </div>
    </div>
    <div class="floor">
      <div class="listWrap">
        <div class="list">
          <div class="item item_hover" @click="goDetails('detail')">
            <img class="caseImg" src="@/assets/images/3.png" alt="" />
            <div class="caseInfo">
              <div class="intro">
                【新手必学】如何找到优质货源新手必学】如何找到优质货源新手必学】如何找到优质货源新手必学】如何找到优质货源
              </div>
              <div class="tags">
                <div class="tag">运营</div>
                <div class="tag">运营</div>
                <div class="tag">运营</div>
              </div>
            </div>
          </div>
          <div class="item item_hover" @click="goDetails('video')">
            <img class="caseImg" src="@/assets/images/2_5.png" alt="" />
            <div class="caseInfo">
              <div class="intro">【新手必学】如何找到优质货源新手必学</div>
              <div class="tags">
                <div class="tag">运营</div>
                <div class="tag">运营</div>
                <div class="tag">运营</div>
              </div>
            </div>
          </div>
          <div class="item item_hover" @click="goDetails('detail')">
            <img class="caseImg" src="@/assets/images/3_2.png" alt="" />
            <div class="caseInfo">
              <div class="intro">【新手必学】如何找到优质货源</div>
              <div class="tags">
                <div class="tag">运营</div>
                <div class="tag">运营</div>
                <div class="tag">运营</div>
              </div>
            </div>
          </div>
          <div class="item item_hover" @click="goDetails('detail')">
            <img class="caseImg" src="@/assets/images/5_2.png" alt="" />
            <div class="caseInfo">
              <div class="intro">【新手必学】如何找到优质货源</div>
              <div class="tags">
                <div class="tag">运营</div>
                <div class="tag">运营</div>
                <div class="tag">运营</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block paginationWrap">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-size="16"
        layout="total, prev, pager, next"
        :total="400"
        :small="size"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      currentPage4: 1,
      size: false,
      selectList: [
        {
          label: '分类',
          id: 0,
          currentIndex: 0,
          children: [
            { tagName: '全部', id: 0 },
            { tagName: '新手必看', id: 1 },
            { tagName: '进阶攻略', id: 2 },
            { tagName: '营销活动', id: 3 },
            { tagName: '疑难解答', id: 4 }
          ]
        }
      ]
    }
  },
  computed: {
    ...mapState(['mobile'])
  },
  mounted() {
    // let bottom = document.getElementsByClassName('bottom')[0]
    // bottom.style.display = 'block'
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
    }
  },
  methods: {
    tagClick(index, id) {
      this.selectList[index].currentIndex = id
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    },
    goDetails(type) {
      if(type=='video'){
        this.$router.push('/video')
      }else{
        this.$router.push('/details')
      }
    },
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/zh-CN/home' })
        return false
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="less">
.el-pagination {
  display: flex;
  justify-content: center;
}
.paginationWrap {
  margin-top: 20px;
}
.pageTitle {
  width: 100%;
  // height: 40px;
  font-family: SourceHanSansSC-Bold;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 40px;
  letter-spacing: 0px;
  color: #333333;
  margin-top: 45px;
}
.container_collect {
  width: 1180px;
  margin: 0 auto;
  .selectWrap {
  width: 100%;
  padding: 13px 0;
  background-color: #ffffff;
  margin-top: 17px;
  margin-bottom: 20px;
}

.listWrap {
  width: 100%;
  height: 260px;
  // background-color: #ffffff;
  margin-top: 37px;
  .list {
    widows: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .item {
      border-radius: 5px;
      height: 100%;
      width: 24%;
      // background: chartreuse;
      overflow: hidden;
      cursor: pointer;
    }
    .caseImg {
      display: block;
      width: 100%;
      height: 155px;
    }
    .caseInfo {
      width: 100%;
      // height: 105px;
      background: #fff;
      padding: 15px 10px 19px 10px;
    }
    .intro {
      width: 100%;
      height: 45px;
      // margin-top: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      margin-bottom: 10px;
    }
    .tags {
      width: 100%;
      height: 21px;
      display: flex;
      justify-content: start;
    }
    .tag {
      padding: 0px 4px;
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ff9d4e;
      border: 1px solid #ff9d4e;
      margin-right: 3px;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.title {
  width: 100%;
  height: 46px;
  margin-top: 40px;
  font-family: SourceHanSansSC-Bold;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

//   筛选
.selectItem {
  width: 100%;
  height: 40px;
  padding: 0 19px;
  .label {
    width: 5%;
    float: left;
    font-family: SourceHanSansSC-Regular;
    font-size: 16px;
    line-height: 40px;
    color: #333333;
  }
  .tags {
    width: 90%;
    float: left;
    height: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .tag {
      padding: 0 4px;
      height: 26px;
      border-radius: 2px;
      font-family: SourceHanSansSC-Regular;
      font-size: 16px;
      line-height: 26px;
      color: #333333;
      cursor: pointer;
    }
    .active {
      background-color: #ffb11a;
      color: #fff;
    }
  }
}

}
.navBar_m {
  display: none;
}
@media screen and (max-width: 760px) {
  #app {
    padding-top: 46px;
  }
  .pageTitle {
    display: none;
  }
  .title {
    margin-top:0;
  }

  .container_collect {
    width: 100%;
      .selectWrap {
    width: 100%;
    padding: 0.19rem 0;
    margin-top: 0;
    margin-bottom: 0.2rem;
  }
  .selectItem {
    width: 100%;
    height: 40px;
    padding: 0 0.19rem;
    .label {
      display: none;
    }
    .tags {
      width: 100%;
      float: left;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tag {
        padding: 0 0.2rem;
        height: auto;
        border-radius: 2px;
        font-family: SourceHanSansSC-Regular;
        font-size: 0.26rem;
        color: #333333;
        cursor: pointer;
        border-radius: 0.26rem;
      }
      .active {
        background-color: #ffb11a;
        color: #fff;
      }
    }
  }
    .listWrap {
      width: 100%;
      height: auto;
      margin-top: 0.21rem;
      .list {
        widows: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        .item {
          border-radius: 5px;
          height: 100%;
          width: 48%;
          // background: chartreuse;
          overflow: hidden;
          cursor: pointer;
          margin-top: 0.13rem;
        }
        .caseImg {
          display: block;
          width: 100%;
          height: auto;
        }
        .caseInfo {
          width: 100%;
          height: auto;
          background: #fff;
          padding: 7px;
        }
        .intro {
          width: 100%;
          height: 32px;
          margin-top: 0.11rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 0.3rem;
          font-size: 0.22rem;
        }
        .tags {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: start;
        }
        .tag {
          padding: 0 0.1rem;
          font-family: SourceHanSansCN-Regular;
          font-size: 0.2rem;
          font-weight: normal;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ff9d4e;
          border: 1px solid #ff9d4e;
          margin-right: 3px;
          box-sizing: border-box;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  .navBar_m {
    display: block;
    width: 100%;
    height: 46px;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .leftIcon {
      width: 40px;
      height: 100%;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #999999;
      position: relative;
      z-index: 6;
    }
    .navBarText_m {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      font-family: SourceHanSansSC-Regular;
      font-size: 18px;
      color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
@media screen and (max-width: 321px) {
  .selectItem {
    width: 100%;
    height: 40px;
    padding: 0 19px;
    .label {
      display: none;
    }
    .tags {
      width: 100%;
      float: left;
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .tag {
        padding: 0 0.15rem;
        height: auto;
        border-radius: 2px;
        font-family: SourceHanSansSC-Regular;
        font-size: 0.26rem;
        color: #333333;
        cursor: pointer;
        border-radius: 0.3rem;
      }
      .active {
        background-color: #ffb11a;
        color: #fff;
      }
    }
  }
}
</style>
