<template>
  <div class="container container_fblist">
    <div class="navBar_m">
      <div class="leftIcon iconfont icon-fanhui" @click="back()"></div>
      <div class="navBarText_m">意见反馈</div>
    </div>
    <router-link class="list" to="/FeedbackM">
      <div class="left">
        <span class="iconfont icon-fankuixuanzhong"></span>
        <span class="leftText">意见反馈</span>
      </div>
      <div class="right iconfont icon-jiantou"></div>
    </router-link>
    <router-link class="list" to="/FeedbackListM">
      <div class="left">
        <span class="iconfont icon-ai-message"></span>
        <span class="leftText">回复通知</span>
      </div>
      <div class="right iconfont icon-jiantou"></div>
    </router-link>
  </div>
</template>
icon-ai-message
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {}
  },
  mounted() {
    if (this.mobile) {
      this.size = true
      let navBar = document.getElementsByClassName('navWrap')[0]
      navBar.style.display = 'none'
      // let bottom = document.getElementsByClassName('bottom')[0]
      // bottom.style.display = 'none'
    }
  },
  computed: {
    ...mapState(['mobile'])
  },
  methods: {
    back() {
      if (window.history.length <= 1) {
        this.$router.push({ path: '/zh-CN/home' })
        return false
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="less">
.container_fblist {
  width: 100%;
  .list {
    width: 100%;
    height: 0.88rem;
    background: #fff;
    border-bottom: 1px solid #f7f6fb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      padding: 0 0.34rem;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .iconfont {
        font-size: 0.34rem;
        color: #bfbfbf;
        margin-right: 0.25rem;
      }
      .leftText {
        font-family: SourceHanSansCN-Normal;
        font-size: 0.28rem;
        color: #424242;
      }
    }
    .right {
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #bfbfbf;
      font-size: 0.36rem;
    }
  }
}
.navBar_m {
  display: block;
  width: 100%;
  height: 46px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .leftIcon {
    width: 40px;
    height: 100%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    position: relative;
    z-index: 6;
  }
  .navBarText_m {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    font-family: SourceHanSansSC-Regular;
    font-size: 18px;
    color: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
